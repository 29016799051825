import React from "react";
import { aboutImg } from "../../constants/imports";
 

const About = () => {
  return (
    <div className="res-cont grid grid-cols-1 md:grid-cols-2 justify-start items-center">
      {/* Description */}
      <div className="  md:pr-10 pr-0 w-full animate__rubberBand ">
        <h1 className="small-heading">About RemotewebNET Dynamics</h1>
        <h1 style={{}} className="heading ">
          We Specialise in Providing Cost-effective Digital Solution
        </h1>
        <p className="font-light text-customBlue text-md text-left mt-8">
          We are RemotewebNET Dynamics, a leading provider of IT consulting,
          Technical support, and IT Solutions for medium and large-sized
          businesses. It’s a privately owned company founded in the year 2012
          with its headquarters located in Abuja Nigeria. Our experienced
          technicians and engineers work with clients to understand their unique
          technology needs and provide solutions
        </p>
      </div>
      {/* Image */}
      <div className="flex items-center md:justify-end justify-center">
        <img src={aboutImg} alt="About Us" className="w-[300px]" />
      </div>
    </div>
  );
};

export default About;
