import React from "react";
import { Footer, Header } from "../../../component/Layout";
import MainPageHero from "../../../component/Events/MainPageHero";
import Button from "../../../component/Events/Button";
import { CiLocationOn } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { IoMdCalendar, IoMdShare } from "react-icons/io";
import { SlCalender } from "react-icons/sl";
import { WiTime4 } from "react-icons/wi";

import { mentorship2025Details } from "../../../constants/data";
import SpeakersCarousel from "../../../component/Events/SpeakersCarousel";
import { ScrollToTop } from "../../../App";

export default () => {
  const navigate = useNavigate();

  return (
    <div className="archivo">
      <Header />
              <ScrollToTop/>
      
      <MainPageHero />
      <section className="w-full p-3 md:px-[100px] md:py-10">
        <div className="flex justify-between flex-col md:flex-row gap-2">
          <h2 className="text-xl md:text-[40px]  md:leading-[60px] w-3/6">RemotewebNET Dynamics Ltd. Mentorship Programme</h2>
          <div className="flex gap-3 !h-[90px] ">
            {" "}
            <Button
              width={200}
              disabled={true}
              text="Register"
              onBtnClick={() => {
                navigate("/mentorship2025/register");
              }}
            />
            <Button
              width={200}
              outlined
              text={<IoMdShare className="text-mainPink" />}
              onBtnClick={() => {
                //Copy to clipboard
              }}
            />
          </div>
        </div>

        <div className="flex  border-gray-300 border p-4 flex-col my-4 rounded-lg ">
          <p className="font-bold text-[24px] text-gray-700 ">
            Event Description{" "}
          </p>
          <p className="font-[400] leading-[40px] text-[18px] text-gray-600">
            Join us for a transformative one-day virtual experience where
            technology meets real-world insight. The RemotewebNET Mentorship
            Programme | July 2025 is designed to empower youths with cutting-edge
            skills and actionable strategies from top industry professionals.
            Immerse yourself in interactive sessions, hands-on workshops, and
            dynamic discussions that reveal the latest trends in tech
            innovation, software development, and digital transformation. This
            programme is more than just a learning opportunity—it’s your
            launchpad for accelerated career growth. Connect with like-minded
            peers, engage in thought-provoking conversations, and gain exclusive
            insights that will equip you to thrive in the competitive tech
            landscape. Whether you’re just starting out or looking to refine
            your expertise, this event offers the guidance, inspiration, and
            network you need to unlock your full potential. Don’t miss your
            chance to be part of this cutting-edge experience. Elevate your
            skills, expand your horizons, and set your career on an upward
            trajectory with the RemotewebNET Mentorship Programme. Secure your
            spot today and take the next step in your tech journey!
          </p>
        </div>
        <div className="text-[20px] font-[500] flex gap-4 text-gray-700 flex-col md:flex-row">
          <div className="md:w-1/6 border  p-3 flex flex-col gap-3 border-gray-300 rounded-lg ">
            <p>
              <SlCalender size={20} className="inline text-black mx-2  font-semibold" />{" "}
              {mentorship2025Details.date}
            </p>
            <p >
              <WiTime4 size={20} className="inline text-black mx-2 font-semibold" />

              {mentorship2025Details.time}
            </p>
          </div>
          <div className="md:w-5/6 border  p-3 flex   gap-3 border-gray-300 rounded-lg  flex-col md:flex-row">
        <p className="flex">
         <CiLocationOn
              size={40} className="inline text-black mx-2 font-semibold" />  <p className="">
             
              {mentorship2025Details.venue}
            </p>  </p>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31520.541151922775!2d7.489880098562547!3d9.057602115301245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104dd42594163dd1%3A0x2b97d2308e5f8ee1!2sTranscorp%20Hilton%20Abuja!5e0!3m2!1sen!2sng!4v1742752925646!5m2!1sen!2sng"  className="md:w-[600px] h-[150px]" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
             
          </div>
        </div>


        <div>
       {/* <SpeakersCarousel/> */}
        </div>
      </section>
      <Footer />
    </div>
  );
};
